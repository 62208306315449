/* medienhaus.css */

@import url("./normalize.css");
@import url("./fonts.css");

:root {
  --color-fg: rgb(0, 0, 0);
  --color-bg: rgb(255, 255, 255);
  --color-hi: rgb(255, 240, 170);
  --color-no: rgb(255, 96, 96);
  --color-me: rgb(128, 128, 128);
  --color-lo: rgb(240, 240, 240);
  --margin: 1rem;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: unset;
  padding: unset;
}

*::selection {
  background-color: var(--color-hi);
}

html {
  background-color: var(--color-bg);
  color: var(--color-fg);
  font-family: "Inter", sans-serif;
  font-size: calc(1em + 0.5vmin);
  font-style: normal;
  font-weight: 500;
  height: 100%;
  line-height: 1.3;
  scroll-behavior: smooth;
}

body {
  font-size: 1rem;
  height: 100%;
  padding-left: var(--margin);
  padding-right: var(--margin);
  opacity: 0;
  animation: init 0.03s ease-in 0.5s 1 forwards;
}

body>div:first-of-type {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: auto 1fr auto;
  grid-gap: 0 calc(var(--margin) * 2.8);
  min-height: 100%;
}

body>div:first-of-type>*:not(footer)>*:not(section)>*+* {
  margin-top: calc(var(--margin) * 2);
}

body>div:first-of-type>main> :first-child {
  margin-top: calc(var(--margin) * 0.6);
}

/*
body > div:first-of-type > main > :first-of-type#definition {
  margin-top: calc(var(--margin) * 1.6);
}
*/

body>div:first-of-type>main>section+section {
  margin-top: calc(var(--margin) * 2);
}

a:link {
  color: var(--color-fg);
  text-decoration: none;
}

a:visited {
  color: var(--color-fg);
}

a:hover {
  color: var(--color-fg);
  text-decoration: underline;
}

a:focus {
  color: var(--color-fg);
}

a:active {
  color: var(--color-fg);
}

code {
  background-color: var(--color-lo);
  font-family: "Roboto Mono", monospace;
  font-weight: normal;
  padding: 0 calc(var(--margin) * 0.2);
}

code, pre, kbd, samp,
code a, pre a, kbd a, samp a {
  font-family: "Roboto Mono", monospace;
  font-weight: normal;
}

em, i {
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: unset;
}

h1, h2, h3 {
  font-weight: bold;
}

hr {
  border-color: var(--color-fg);
  border-radius: unset;
  border-style: solid;
  border-width: calc(var(--margin) * 0.05);
  margin: var(--margin) 0;
}

strong, b {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

a[rel~="external"] {
  white-space: nowrap;
}

a[rel~="external"]::after {
  content: "\2197";
  display: inline-block;
  font-size: 0.6rem;
  font-weight: 900;
  vertical-align: super;
}

img {
  object-fit: cover;
  width: 100%
}

/*
img[alt=""], img:not([alt]) {
  border: 3px dashed rgb(255,0,0);
}
*/

footer {
  display: grid;
  grid-auto-flow: column;
  grid-row: 3;
  grid-column: 1 / 2;
  margin-top: calc(var(--margin) * 4);
  margin-bottom: calc(var(--margin) * 1.4);
}

header {
  background-color: var(--color-bg);
  grid-row: 1;
  grid-column: 1;
  padding-top: calc(var(--margin) * 1.4);
  padding-bottom: calc(var(--margin) * 1.4);
  position: sticky;
  top: 0;
  z-index: 1;
}

header>a {
  font-weight: bold;
}

header>a>h1 {
  display: inline-block;
}

header>a[href$="/login"],
header>button {
  display: none;
}

main {
  grid-row: 2;
  grid-column: 1;
  max-width: 55ch;
}

main a:link {
  text-decoration: underline;
}

/*
main section + section,
*/
main section>*+* {
  margin-top: var(--margin);
}

main ol,
main ul {
  list-style-position: inside;
}

main ul {
  list-style-type: "\2013\0020";
}

form>*+* {
  margin-top: var(--margin);
}

form > div > * + *,
.wp-block-medienhaus-image > * + *,
.wp-block-medienhaus-audio > * + *{
  margin-top: calc(var(--margin) * 0.5);
}

nav {
  grid-row: 1 / 3;
  grid-column: 2;
}

nav>div {
  display: grid;
  position: sticky;
  top: calc(var(--margin) * 1.4);
}

/*
nav + section {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-gap: var(--margin);
  grid-row: 3;
  grid-column: 2;
  align-self: end;
  justify-content: end;
  position: sticky;
  bottom: var(--margin);
}

nav + section > button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-bg);
  border: unset;
  color: var(--color-fg);
  cursor: pointer;
  line-height: 1.4;
}

nav + section > button:hover {
  text-decoration: underline;
}
*/

.languageSelector {
  width: 7ch;
}

nav .languageSelector {
  position: fixed;
  bottom: var(--margin);
  right: var(--margin);
}

nav>*>*>a+a {
  margin-top: calc(var(--margin) * 0.55);
}

nav a {
  display: block;
  font-weight: bold;
}

nav a.disabled {
  pointer-events: none;
  opacity: 0.2;
}

/*
section a:link {
  text-decoration: underline;
}

section h2,
section h3 {
  font-weight: bold;
}

section blockquote {
  font-size: 2rem;
  line-height: 1.2;
}

section ul {
  list-style-type: "\2013\0020";
  list-style-position: inside;
}

section ul li + li {
  margin-top: calc(var(--margin) * 0.5);
}
*/

::placeholder {
  color: var(--color-me);
  opacity: unset;
}

button {
  text-transform: uppercase;
}

button,
input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border-color: var(--color-fg);
  border-radius: unset;
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  color: var(--color-bg);
  cursor: pointer;
  font-weight: 600;
  height: calc(var(--margin) * 2.5);
  padding: calc(var(--margin) * 0.4) calc(var(--margin) * 0.5);
  width: 100%;
}

button[disabled],
input[type="submit"][disabled] {
  background-color: var(--color-me);
  border-color: var(--color-me);
  cursor: not-allowed;
}

input[id*="datalist"],
input[type="color"],
input[type="date"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="password"],
input[type="url"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-bg);
  border: unset;
  border-color: var(--color-fg);
  border-radius: unset;
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  box-shadow: none;
  color: var(--color-fg);
  height: calc(var(--margin) * 2.5);
  padding: calc(var(--margin) * 0.4) calc(var(--margin) * 0.55);
  width: 100%;
}

input[id*="datalist"][disabled],
input[type="color"][disabled],
input[type="date"][disabled],
input[type="email"][disabled],
input[type="number"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="text"][disabled],
input[type="time"][disabled],
input[type="password"][disabled] {
  border-color: var(--color-me);
  color: var(--color-me);
  cursor: not-allowed;
}

/* TODO, maybe? third selector looks weird */
input::-webkit-input-placeholder,
input::-moz-placeholder input :placeholder-shown {
  padding-left: calc(var(--margin) * 1);
  margin-left: calc(var(--margin) * 1);
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: unset;
  border-color: var(--color-fg);
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  box-shadow: none;
  cursor: pointer;
  /*
  outline: none;
  */
  height: calc(var(--margin) * 2);
  width: calc(var(--margin) * 2);
}

input[type="checkbox"] {
  border-radius: unset;
}

input[type="radio"] {
  border-radius: 50%;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background-color: var(--color-fg);
  color: var(--color-bg);
  font-size: 1.4rem;
  text-align: center;
}

input[type="checkbox"]:checked::after {
  content: '\2713';
}

input[type="color"] {
  cursor: pointer;
}

input[type="color"]::-webkit-color-swatch,
input[type="color"]::-moz-color-swatch {
  border: unset;
}

input[type="date"],
input[type="time"] {
  cursor: auto;
}

input[type="file"] {
  /*
  align-self: center;
  color: transparent;
  */
  width: 100%;
}

input[type="file"]::file-selector-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border: unset;
  border-color: var(--color-fg);
  border-radius: unset;
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  box-shadow: none;
  color: var(--color-bg);
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-weight: 600;
  height: calc(var(--margin) * 2.5);
  padding: calc(var(--margin) * 0.4) calc(var(--margin) * 0.5);
  text-transform: uppercase;
  /*
  width: 100%;
  */
}

/*
input[type="radio"]:checked::after {
  content: '\00b7';
}
*/

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border: unset;
  box-shadow: none;
  cursor: pointer;
  height: calc(var(--margin) * 0.3);
}

/* TODO */
/*
input[type="range"]::-moz-focus-outer {
  border: unset;
}

input[type="range"]:hover {
  outline:none;
}
*/

/* TODO */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border: unset;
  border-radius: unset;
  box-shadow: none;
  /*
  height: 9px;
  width: 9px;
  */
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border: unset;
  border-radius: unset;
  box-shadow: none;
}

input[type="range"]::-moz-range-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border: unset;
  box-shadow: none;
  height: calc(var(--margin) * 0.3);
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-lo);
  border: unset;
  box-shadow: none;
  height: calc(var(--margin) * 0.3);
}

input[id$="-select"],
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-bg);
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwMHB4IiB3aWR0aD0iMzAwcHgiIGZpbGw9InJnYigxMjgsMTI4LDEyOCkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHg9IjBweCIgeT0iMHB4Ij48cG9seWdvbiBwb2ludHM9IjUwIDU3LjEzIDIzLjE5IDMwLjQ2IDE2LjEzIDM3LjU1IDUwIDcxLjIzIDgzLjg2IDM3LjU1IDc2LjgxIDMwLjQ2IDUwIDU3LjEzIj48L3BvbHlnb24+PC9zdmc+);
  background-position: calc(100% - calc(var(--margin) * 0.4)) 55%;
  background-repeat: no-repeat;
  background-size: var(--margin);
  border: unset;
  border-radius: unset;
  border-color: var(--color-fg);
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  box-shadow: none;
  color: var(--color-fg);
  cursor: pointer;
  height: calc(var(--margin) * 2.5);
  padding: calc(var(--margin) * 0.4) calc(var(--margin) * 0.55);
  text-overflow: ellipsis;
  width: 100%;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--color-fg);
}

select:-moz-focusring * {
  color: var(--color-fg);
  text-shadow: none;
}

select[disabled] {
  border-color: var(--color-me);
  color: var(--color-me);
  cursor: not-allowed;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-bg);
  border-color: var(--color-fg);
  border-radius: unset;
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  box-shadow: none;
  color: var(--color-fg);
  padding: calc(var(--margin) * 0.4) calc(var(--margin) * 0.5);
  resize: vertical;
  width: 100%;
}

textarea[disabled] {
  border-color: var(--color-me);
  color: var(--color-me);
  cursor: not-allowed;
  resize: none;
}

.account>div>*+* {
  margin-top: var(--margin);
}

/*
.account > .profile-image > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
*/

.account .avatar {
  height: calc(var(--margin) * 8);
  width: calc(var(--margin) * 8);
}

.account canvas.avatar {
  background-color: var(--color-fg);
}

.account img.avatar {
  background-color: var(--color-bg);
}

.admin .check,
.admin .radio {
  display: grid;
  align-items: center;
  grid-gap: var(--margin);
  grid-template-columns: minmax(10ch, auto) 1fr;
}

.cancel {
  background-color: var(--color-bg);
  color: var(--color-fg);
}

/*
.confirm {
  background-color: var(--color-fg);
  color: var(--color-bg);
}
*/

.confirmation {
  display: grid;
  /*
  grid-auto-flow: column;
  */
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--margin);
}

.contributors>.permissions>*+* {
  margin-top: var(--margin);
}

.credits>ul,
.credits>ul>li {
  display: grid;
  align-items: center;
  grid-gap: var(--margin);
}

.credits>ul>li {
  grid-template-columns: 1fr 2rem;
}

.credits>ul>li>span {
  /*
  word-break: break-all;
  */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
.event {
  align-self: center;
  background-image: none;
  background-position: none;
  background-repeat: none;
  display: inline;
}
*/
.editor {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: var(--margin);
}

.editor .left,
.editor .right {
  background-image: linear-gradient(var(--color-me), var(--color-me));
  background-position: center center;
  background-repeat: no-repeat;
  background-size: calc(var(--margin) * 0.1) 100%;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-gap: var(--margin);
}

.editor .left>*,
.editor .right>* {
  border-color: var(--color-bg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
}

.editor .left> :nth-child(1) {
  grid-row: 1;
  align-self: start;
  margin-top: calc(var(--margin) * -0.2);
}

.editor .left> :nth-child(2) {
  background-color: var(--color-bg);
  display: grid;
  grid-row: 2;
  align-self: center;
}

.editor .left> :nth-child(2)>* {
  max-width: 80%;
  place-self: center;
}

.editor .left> :nth-child(3) {
  grid-row: 3;
  align-self: end;
  margin-bottom: calc(var(--margin) * -0.2);
}

.editor .center {
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
}

.editor .center.leaflet-container {
  height: calc(var(--margin) * 12);
}

.editor .center.leaflet-container+* {
  margin-top: var(--margin);
}

/*
.editor .center div {
  height: 100%;
}
*/

.editor .right> :only-child {
  grid-row: 2;
  align-self: center;
}

.editor .left>button,
.editor .right>button {
  display: grid;
  place-content: center;
}

.editor .left,
.editor .left>*,
.editor .right,
.editor .right>* {
  min-height: 2rem;
  width: 2rem;
}

.events select+p {
  margin-top: var(--margin);
}

.map {
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  display: grid;
  grid-gap: var(--margin);
  height: calc(var(--margin) * 12);
  margin-top: var(--margin);
}

.invites>ul,
.invites>ul>li {
  display: grid;
}

.invites>ul>li {
  grid-gap: var(--margin);
  /*
  grid-template-columns: 1fr 12ch;
  */
}

.license-info>*+* {
  margin-top: var(--margin);
}

.loading {
  border-color: var(--color-fg) transparent var(--color-fg) transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  height: 2rem;
  width: 2rem;
  animation: loading 2.4s linear infinite;
}

button>.loading {
  border-color: var(--color-bg) transparent var(--color-bg) transparent;
  margin: 0 auto;
  max-height: calc(var(--margin) * 1.15);
  max-width: calc(var(--margin) * 1.15);
}

.login form div:nth-of-type(1) div {
  display: grid;
  grid-gap: var(--margin);
  grid-auto-flow: row;
}

.login label+*,
.login ul>li+li {
  margin-top: calc(var(--margin) * 0.5);
}

.maxlength {
  display: grid;
}

.maxlength>span {
  /*
  background-color: var(--color-bg);
  justify-self: end;
  margin-right: calc(var(--margin) * 0.5);
  margin-top: calc(var(--margin) * -1);
  */
  font-size: 0.6rem;
  justify-self: end;
  min-width: 7ch;
  /*
  padding: calc(var(--margin) * 0.2) calc(var(--margin) * 0.4);
  */
  margin-top: calc(var(--margin) * 0.2);
  text-align: right;
}

.notification {
  color: var(--color-no);
  display: inline-block;
  font-size: 0.6rem;
  font-weight: 900;
  vertical-align: super;
}

.notification.hidden {
  visibility: hidden;
}

.project {
  display: grid;
  grid-gap: var(--margin);
}

/*
.project .above  {
  grid-column: 1 / -1;
  grid-row: 1;
}
*/

.project .left {
  background-color: var(--color-fg);
  font-size: 0;
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
}

.project .left img {
  height: 100%;
}

/*
.project .left,
.project .center {
*/
.project .left {
  /*
  grid-row: 2 / -1;
  */
  height: calc(var(--margin) * 7.8);
}

.project .center {
  overflow-y: hidden;
}

.project .center p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
.project .right {
  display: grid;
  grid-gap: var(--margin);
  grid-template-rows: repeat(3, 2rem);
}
*/

/*
.project :nth-child(4) {
  grid-column: 3;
  grid-row: 2;
}

.project :nth-child(5) {
  grid-column: 3;
  grid-row: 3;
}

.project :nth-child(6) {
  grid-column: 3;
  grid-row: 4;
}
*/

.project .below,
.visibility .below {
  display: grid;
  /*
  grid-column: 1 / -1;
  */
  grid-auto-flow: row;
  grid-gap: var(--margin);
}

.project .below div,
.visibility .below div {
  display: grid;
  grid-template-columns: 2rem auto;
  grid-gap: var(--margin);
}

.project .below div> :nth-child(1),
.visibility .below div> :nth-child(1) {
  grid-column: 1;
}

.project .below div> :nth-child(2),
.visibility .below div> :nth-child(2) {
  grid-column: 2;
}

.request {
  display: grid;
  grid-auto-flow: column;
}

.request > * + * {
  margin-top: unset;
}

.requests>div {
  display: grid;
  grid-gap: var(--margin);
}

.requests>div>p {
  /*
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  */
  word-break: break-word;
}

.save {
  margin-top: calc(var(--margin) * 3) !important;
}

.terms>div {
  display: grid;
  grid-template-columns: 2rem auto;
  grid-gap: var(--margin);
}

.terms>div>label {
  font-family: "Courier New", "Courier", monospace;
  font-size: 0.7rem;
  line-height: normal;
}

.terms>div>input[type="checkbox"] {
  font-family: "Courier New", "Courier", monospace;
}

/*
.toggle {
  display: grid;
  align-items: center;
  grid-gap: var(--margin);
  grid-template-columns: 2rem 1fr;
}
*/

/*
.terms > div > input[type="checkbox"]:checked {
  background-color: var(--color-bg);
  color: var(--color-fg);
}
*/

.timedate {
  display: grid;
  grid-gap: var(--margin);
}

.timedate > div {
  display: grid;
  grid-gap: var(--margin);
  grid-template-columns: repeat(2, 1fr);
}

/*
.timestamp {
}
*/

/*
.account > div {
  display: grid;
  align-items: center;
  grid-gap: var(--margin);
  grid-template-columns: auto 1fr;
}

.account button {
  background-color: var(--color-hi);
  border-color: var(--color-hi);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  color: var(--color-bg);
  cursor: pointer;
  padding: calc(var(--margin) * 0.2);
}

.account button[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.avatar {
  height: calc(var(--margin) * 2.8);
  width: calc(var(--margin) * 2.8);
}

.explore > * + div {
  margin-top: calc(var(--margin) * 1) !important;
}

.explore > div {
  display: grid;
  align-items: center;
  grid-gap: var(--margin);
  grid-template-columns: auto 1fr 11ch;
}

.explore > div > label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.explore > h2 {
  text-decoration: underline;
}

.explore button {
  background-color: var(--color-fg);
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  color: var(--color-bg);
  cursor: pointer;
  padding: calc(var(--margin) * 0.2);
  text-transform: uppercase;
  width: 100%;
}

.explore button[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.explore button > .loading {
  border-color: var(--color-bg) transparent var(--color-bg) transparent;
  max-height: calc(var(--margin) * 1.15);
  max-width: calc(var(--margin) * 1.15);
  margin:0 auto;
}

.explore input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  box-shadow: none;
  outline: none;
  padding: calc(var(--margin) * 0.2);
}

.explore form#server div {
  grid-template-columns: 1fr 1fr;
}

.explore form#advanced div {
  grid-template-columns: 5ch 1fr;
}
*/

.faq code {
  background-color: var(--color-lo);
  padding: 0 calc(var(--margin) * 0.2);
}

.support h3+* {
  /*
  margin-top: calc(var(--margin) * 0.5);
  */
  margin-top: var(--margin);
}

/*
.request #formchooser {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--margin);
}

.request #formchooser input[type="radio"]::after {
  content: attr(name);
  text-align: center;
  text-transform: uppercase;
}

.request #formchooser input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  color: var(--color-bg);
  cursor: pointer;
  opacity: 0.3;
  padding: calc(var(--margin) * 0.2);
  text-align: center;
  width: 100%;
}

.request #formchooser input[type="radio"]:checked,
.request #formchooser input[type="radio"]:checked + label {
  cursor: auto;
  opacity: 1;
}

.request #formchooser input[type="radio"]:not(:checked):hover {
  opacity: 1;
}

.request #formchooser input[type="radio"] + label {
  display: none;
}

.kino {
  margin-top: calc(var(--margin) * 1) !important;
}

.kino .sidebar {
  font-size: 0.55rem;
}

.kino .sidebar button {
  background-color: var(--color-fg);
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  color: var(--color-bg);
  cursor: pointer;
  display: inline-grid;
  place-content: center;
  height: calc(var(--margin) * 1.4);
  width: calc(var(--margin) * 1.4);
}

.kino .sidebar ul {
  list-style-type: none;
}

.kino .sidebar ul li {
  display: grid;
  grid-auto-flow: column;
  grid-gap: calc(var(--margin) * 0.5);
  align-items: center;
  justify-content: start;
}

.kino .sidebar .playicon {
  border-color: transparent transparent transparent var(--color-bg);
  border-style: solid;
  border-width: calc(var(--margin) * 0.2) 0 calc(var(--margin) * 0.2) calc(var(--margin) * 0.325);
  margin-left: calc(var(--margin) * calc(0.325 / 4));
  pointer-events: none;
}

.kino .sidebar .playicon.playing {
  border-color: var(--color-bg);
  border-width: calc(var(--margin) * 0.2) 0 calc(var(--margin) * 0.2) calc(var(--margin) * 0.4);
  margin-left: 0;
}

.react-player {
  position: absolute;
  left: 0;
  top: 0;
}

.react-player__preview {
  background-color: var(--color-fg);
}

.react-player__play-icon {
  border-color: var(--color-bg);
}

.react-player__shadow {
  background: unset;
}

.video > div {
  position: sticky;
  top: calc(var(--margin) * 4.8);
}

.videoplayer {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
}

.videowrapper {
  padding-top: 56.25%;
  position: relative;
}

.videowrapper > progress:not([value]) {
  display: none;
}

.videowrapper > progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: unset;
  height: calc(var(--margin) * 0.4);
  width: 100%;
}

.videowrapper > progress[value]::-webkit-progress-value,
.videowrapper > progress[value]::-moz-progress-bar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border: unset;
}

.with-sidebar > * {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--margin) / 2 * -1);
}

.with-sidebar > * > * {
  flex-grow: 1;
  margin: calc(var(--margin) / 2);
}

.with-sidebar > * > :first-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(70% - var(--margin));
}
*/

@keyframes init {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --color-fg: rgb(255, 255, 255);
    --color-bg: rgb(20, 20, 20);
    --color-hi: rgb(228, 9, 59);
    --color-no: rgb(255, 96, 96);
    --color-me: rgb(128, 128, 128);
    --color-lo: rgb(40, 40, 40);
  }
}

/* 479px */
@media (max-width: 29.938em) {
  header {
    grid-column: 1 / 3;
  }

  main {
    grid-column: 1 / 3;
  }

  nav {
    display: block;
    visibility: hidden;
    background-color: var(--color-bg);
    padding: var(--margin);
    position: fixed;
    top: calc((var(--margin) * 4) - 1px);
    left: 6px;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: all 100ms ease-in-out;
    /*
    opacity: 0.5;
    */
  }

  nav.active {
    visibility: visible;
    left: 0;
    /*
    opacity: 1;
    */
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: var(--margin);
    padding-bottom: var(--margin);
  }

  header>a[href$="/login"],
  header>button {
    display: initial;
  }

  header > button {
    background-color: transparent;
    border: unset;
    color: var(--color-fg);
    flex: 0 1;
    height: calc(var(--margin) * 2);
    line-height: calc(var(--margin) * 2);
    padding: 0;
    margin: 0;
    min-width: calc(var(--margin) * 2) !important;
  }

  header > button.close {
    font-size: 1.6rem;
  }

  header > button svg {
    fill: var(--color-fg);
    width: var(--margin);
  }

  footer {
    grid-column: 1 / 3;
    align-items: center;
  }

  footer .languageSelector {
    grid-column: 3;
    justify-self: end;
  }

  /*
  form input {
    width: 100%;
  }
  form label + * {
    margin-top: calc(var(--margin) * 0.5);
  }
  form select {
    background-position: calc(100% - calc(var(--margin) * 0.6)) 55%;
    width: 100%;
  }
  form input,
  form select {
    border-color: var(--color-fg);
    border-style: solid;
    border-radius: unset;
    border-width: calc(var(--margin) * 0.2);
  }
  form button,
  form input,
  form select {
    padding: calc(var(--margin) * 0.6);
  }
  */
  /*
  .explore input {
    width: 100%;
  }
  .explore #toolbar * + * {
    margin-top: var(--margin);
  }
  */
}

/* 480px */
@media (min-width: 30em) {
  .login form div:nth-of-type(1) div {
    grid-template-columns: auto 20ch;
  }

  /* TODO tools */
  .boilerplate div {
    display: grid;
    align-items: center;
    grid-gap: var(--margin);
    grid-template-columns: minmax(10ch, auto) 1fr;
  }

  .boilerplate div.grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  /*
  form input {
    min-width: 10ch;
  }
  */
  footer .languageSelector {
    display: none;
  }
}

/* 640px */
@media (min-width: 40em) {
  body {
    padding-left: calc(var(--margin) * 2);
    padding-right: calc(var(--margin) * 2);
  }

  nav .languageSelector {
    bottom: var(--margin);
    right: calc(var(--margin) * 2);
  }

  .project {
    grid-template-columns: calc(var(--margin) * 7.8) auto 12ch;
    grid-template-rows: repeat(3, auto);
  }

  .project .above {
    grid-column: 1 / -1;
    grid-row: 1;
  }

  .project .left,
  .project .center {
    grid-row: 2 / -1;
    height: calc(var(--margin) * 7.8);
  }

  .project :nth-child(4) {
    grid-column: 3;
    grid-row: 2;
    align-self: center;
  }

  .project :nth-child(5) {
    grid-column: 3;
    grid-row: 3;
    align-self: center;
  }

  .project :nth-child(6) {
    grid-column: 3;
    grid-row: 4;
  }

  .project .below,
  .visibility .below {
    /*
    display: grid;
    */
    grid-column: 1 / -1;
    /*
    grid-auto-flow: row;
    grid-gap: var(--margin);
    */
  }

  /*
  .project .below div,
  .visibility .below div {
    display: grid;
    grid-template-columns: 2rem auto;
    grid-gap: var(--margin);
  }
  .project .below div > :nth-child(1),
  .visibility .below div > :nth-child(1) {
    grid-column: 1;
  }
  .project .below div > :nth-child(2),
  .visibility .below div > :nth-child(2) {
    grid-column: 2;
  }
  */
}

/* fallback for list-style-type: "<string>"; */
@supports not (list-style-type: "\2013\0020") {
  main ul li::before {
    content: "\2013\0020";
  }
}

@import url("./leaflet.css");
/*
@import url("./fonts.css");
*/

.leaflet-container {
  z-index: 0;
}

.leaflet-container,
.leaflet-container a:link,
.leaflet-container a:hover {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.contextDropdown ul {
  position: absolute;
  overflow: auto;
  max-height: 50vh;
  background-color: var(--color-bg);
  width: 100%;
  border: solid var(--color-fg);
  border-width: 0 3px 3px 3px;
  z-index: 9999;
}

@media (max-width: 29.938em) {
  .contextDropdown ul {
    max-height: 70vh;
  }
}

.contextDropdown ul li {
  cursor: pointer;
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contextDropdown ul li div small {
  color: gray;
}

.contextDropdown ul li.disabled {
  cursor: default;
}

.contextDropdown ul li.disabled div {
  opacity: 0.6;
}

.contextDropdown ul li.selected {
  background-color: var(--color-fg);
  color: var(--color-bg)
}

.contextDropdown ul li:not(.disabled):not(.selected):hover {
  background-color: var(--color-lo);
}

.wp-block {
  /*border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  padding: 0.5em;*/
  margin: var(--margin);
}

.block-editor-writing-flow {
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  padding: 0.5em;
}

.block-editor-writing-flow ol {
  list-style-position: inside;
}
